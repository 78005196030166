import styled, { css } from 'styled-components';

type VideoPlayerControlsWrapperProps = {
  isVisible: boolean;
};

const VideoPlayerControlsWrapper = styled.div<VideoPlayerControlsWrapperProps>`
  opacity: 0;
  pointer-events: none;

  ${({ isVisible }) => isVisible
    && css`
      opacity: 1;
      pointer-events: initial;
    `}
`;

export default VideoPlayerControlsWrapper;
