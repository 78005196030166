import React from 'react';
import { useIntl } from 'react-intl';
import VideoPlayerControl, { VideoPlayerControlVariantEnum } from './VideoPlayerControl';

const { Icon } = UiComponentLibrary;

type PlayPauseVideoPlayerControlProps = {
  onClick: React.MouseEventHandler;
  isPlaying: boolean;
}
function PlayPauseVideoPlayerControl({ isPlaying, onClick }: PlayPauseVideoPlayerControlProps) {
  const intl = useIntl();
  const playPauseControlAriaLabel = intl.formatMessage({
    id: isPlaying ? 'ACCESSIBILITY_VIDEO_PLAYER_PAUSE'
      : 'ACCESSIBILITY_VIDEO_PLAYER_PLAY',
  });
  return (
    <VideoPlayerControl
      variant={VideoPlayerControlVariantEnum.PLAY_PAUSE}
      onClick={onClick}
      aria-label={playPauseControlAriaLabel}
    >
      <Icon name={isPlaying ? 'pause' : 'play'} />
    </VideoPlayerControl>
  );
}

export default PlayPauseVideoPlayerControl;
