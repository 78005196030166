import { AnyAction } from 'redux';
import { AuthState } from '../../interfaces';
import { AuthActionTypes } from '../actionTypes';

const initialState: AuthState = {
  isPortalLoggedIn: false,
  sessionId: undefined,
};

export default (state = initialState, { type, payload }: AnyAction = { type: null }) => {
  switch (type) {
    case AuthActionTypes.SET_IS_PORTAL_LOGGED_IN:
      return {
        ...state,
        isPortalLoggedIn: payload,
      };
    case AuthActionTypes.SET_SESSION_ID:
      return {
        ...state,
        sessionId: payload,
      };
    default:
      return state;
  }
};
