import styled from 'styled-components';

type VideoPlayerWrapperProps = {
  hasError: boolean;
  isFullscreen: boolean;
};

const VideoPlayerWrapper = styled.div<VideoPlayerWrapperProps>`
  width: 100%;
  position: relative;
  line-height: 0;
  display: flex;

  /* Trick to pre-calculate the video height on Safari based on 16:9 Aspect ratio */
  ${({ isFullscreen }) => !isFullscreen && `
      padding-top: 56.25%;
    `}

  ${({ hasError }) => hasError && `
      span[role="alert"] {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        line-height: 1;
        text-align: center;
      }
    `}
`;

export default VideoPlayerWrapper;
