import React from 'react';
import { useIntl } from 'react-intl';
import VideoPlayerControl, { VideoPlayerControlVariantEnum } from './VideoPlayerControl';

const { Icon } = UiComponentLibrary;

type FullscreenVideoPlayerControlProps = {
  videoRef: HTMLVideoElement,
  wrapper: HTMLDivElement;
  onClick: React.MouseEventHandler
  isFullscreen: boolean;
}
function FullscreenVideoPlayerControl(
  {
    videoRef, wrapper, onClick, isFullscreen,
  }: FullscreenVideoPlayerControlProps,
) {
  const intl = useIntl();
  const fullScreenControlAriaLabel = intl.formatMessage({
    id: isFullscreen ? 'ACCESSIBILITY_VIDEO_PLAYER_EXIT_FULL_SCREEN'
      : 'ACCESSIBILITY_VIDEO_PLAYER_ENTER_FULL_SCREEN',
  });

  const toggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (!document.fullscreenElement) {
      if (wrapper?.webkitEnterFullscreen) {
        wrapper?.webkitEnterFullscreen();
      } else if (wrapper?.requestFullscreen) {
        wrapper?.requestFullscreen();
        // iOS does not support Fullscreen API and allows only video element to go fullscreen
      } else if (videoRef?.webkitEnterFullscreen) {
        videoRef?.webkitEnterFullscreen();
      }
    }
  };

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick(event);
    toggleFullscreen();
  };

  return (
    <VideoPlayerControl
      variant={VideoPlayerControlVariantEnum.FULL_SCREEN}
      onClick={onClickHandler}
      aria-label={fullScreenControlAriaLabel}
    >
      <Icon name={isFullscreen ? 'compress' : 'expand'} />
    </VideoPlayerControl>
  );
}

export default FullscreenVideoPlayerControl;
