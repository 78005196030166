import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { logger } from '../../../services';
import useConfigs from '../../../hooks/useConfigs';
import {
  RootState,
} from '../../../interfaces';
import EventCentre from './EventCentre';

function ImgArenaGolf() {
  const { api: apiConfig, sportsSettings } = useConfigs(['api', 'sportsSettings']);
  const golfConfig = sportsSettings?.integrations.imgArena.eventCentre.golf;
  const [tournamentId, setTournamentId] = useState<string>();
  const eventId = useSelector((state: RootState) => state.event.id);

  useEffect(() => {
    const fetchTournamentId = async () => {
      try {
        const { data } = await axios.get(
          `${apiConfig?.apiUrl}service/sportsbook/events/${eventId}/content/visualization/img`,
          {
            params: {
              cageCode: apiConfig?.cageCode,
            },
          },
        );

        setTournamentId(data.golfTournamentId);
      } catch (error) {
        logger.error('Failed to fetch golf tournament id', {
          ...(error instanceof Error) && { error: error.message || error.toString() },
        });
      }
    };
    if (apiConfig && eventId) {
      fetchTournamentId();
    }
  }, [apiConfig, eventId]);

  if (!tournamentId || !golfConfig || !golfConfig.version) {
    return null;
  }

  return (
    <EventCentre sport="golf" config={golfConfig} eventId={tournamentId} />
  );
}

export default ImgArenaGolf;
