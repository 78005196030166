/* eslint-disable global-require */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import axios from 'axios';
import { authActions, eventActions } from './store/actions';
import defaultTheme from './themes/default';
import GlobalStyle from './components/GlobalStyle';
import ImgArena from './components/integrations/ImgArena';
import useTranslations from './hooks/useTranslations';
import useTimeZone from './hooks/useTimeZone';
import useLocale from './hooks/useLocale';
import { RootState } from './interfaces';
import StaticVideoPlayer from './components/internal/VideoPlayer/StaticVideoPlayer';
import useConfigs from './hooks/useConfigs';

type AppProps = {
  initialArgs?: any;
}

function App({ initialArgs }: AppProps) {
  const timeZone = useTimeZone();
  const locale = useLocale();
  const messages = useTranslations();
  const applicationType = useSelector((state: RootState) => state.application.type);
  const eventId = useSelector((state: RootState) => state.event.id);
  const dispatch = useDispatch();
  const { api: apiConfig } = useConfigs(['api']);

  useEffect(() => {
    const fetchEvent = async () => {
      const { data } = await axios.get(
        `${apiConfig?.apiUrl}service/sportsbook/offering/listview/details`,
        {
          params: {
            eventId,
            cageCode: apiConfig?.cageCode,
          },
        },
      );
      dispatch(eventActions.updateEvent(data));
    };
    const fetchContent = async () => {
      const { data } = await axios.get(
        `${apiConfig?.apiUrl}service/sportsbook/events/content`,
        {
          params: {
            eventId,
            cageCode: apiConfig?.cageCode,
          },
        },
      );
      dispatch(eventActions.updateContent(data));
    };
    if (eventId && apiConfig && applicationType !== 'video') {
      fetchEvent();
      fetchContent();
    }
  }, [dispatch, eventId, apiConfig, applicationType]);

  useEffect(() => {
    const portalLoginStateChangeSubscriber = RSIEventBus.subscribe(
      RSIEventBus.eventTypes.PORTAL_LOGIN_STATE_CHANGE,
      ({ isLoggedIn, sessionId }: { isLoggedIn: boolean, sessionId: string }) => {
        dispatch(authActions.setIsPortalLoggedIn(isLoggedIn));
        dispatch(authActions.setSessionId(sessionId));
      },
    );

    return () => portalLoginStateChangeSubscriber.unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    let unsubscribe: any;
    if (applicationType === 'auto') {
      unsubscribe = RSINavigationHandler.subscribe(
        ({ to }: any) => {
          const hashEventId = to.hash && to.hash.startsWith('#event/') && to.hash.match(/\d+/);
          const newEventId = hashEventId && Number(hashEventId[0]);

          if (to.page === 'sportsbook' && newEventId) {
            dispatch(eventActions.updateEventId(newEventId));
          } else {
            dispatch(eventActions.unloadEvent());
          }
        },
      );
    }

    return () => unsubscribe && unsubscribe();
  }, [dispatch, applicationType]);

  if (!locale || !timeZone || !messages) {
    return null;
  }

  return (
    <IntlProvider timeZone={timeZone} locale={locale} defaultLocale={locale} messages={messages}>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        {applicationType === 'auto' && (
        <ImgArena />
        )}
        {applicationType === 'video' && (
          <StaticVideoPlayer
            source={initialArgs.source}
            autoPlay={initialArgs.autoPlay}
            loop={initialArgs.loop}
            fullscreenEnabled={initialArgs.fullscreenEnabled}
            pipEnabled={initialArgs.pipEnabled}
          />
        )}
      </ThemeProvider>
    </IntlProvider>
  );
}

App.defaultProps = {
  initialArgs: undefined,
};

export default App;
