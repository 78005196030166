import React, { ReactNode } from 'react';
import scriptjs from 'scriptjs';
import { logger } from './services';

type UMDLoaderProps = {
  url: string;
  name: string;
  props?: any;
  children?: ReactNode;
}

type UMDLoaderState = {
  Component?: React.FC;
  error?: string;
}

let scriptType = 'production.min';
if (process.env.NODE_ENV === 'development') {
  scriptType = 'development';
}

class UMDLoader extends React.Component<UMDLoaderProps, UMDLoaderState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    props: {},
    children: undefined,
  };

  constructor(props: UMDLoaderProps) {
    super(props);

    this.state = {
      Component: undefined,
      error: undefined,
    };
  }

  componentDidMount() {
    const { url, name } = this.props;
    const src = url.replace('buildtype', scriptType);

    scriptjs(src, () => {
      let target: any = (window as any)[name];

      if (target) {
        // eslint-disable-next-line no-underscore-dangle
        if (target.__esModule) {
          target = target.default;
        }

        this.setState({
          error: undefined,
          Component: target,
        });
      } else {
        this.setState({
          error: `Cannot load component ${name} at ${src}`,
          Component: undefined,
        });
      }
    });
  }

  render() {
    const { props, children } = this.props;
    const { Component, error } = this.state;

    if (Component) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} />
      );
    }

    if (error) {
      if (process.env.NODE_ENV === 'development') {
        return (
          <pre>{error}</pre>
        );
      }

      logger.error(error);
    }

    return children || null;
  }
}

export default UMDLoader;
