import React from 'react';
import VideoPlayer from './VideoPlayer';

type StaticVideoPlayerProps = {
  source: string;
  autoPlay?: boolean;
  loop?: boolean;
  fullscreenEnabled?: boolean;
  pipEnabled?: boolean;
}

function StaticVideoPlayer({
  source, autoPlay, loop, fullscreenEnabled, pipEnabled,
}: StaticVideoPlayerProps) {
  return (
    <VideoPlayer
      source={source}
      autoPlay={autoPlay}
      loop={loop}
      fullscreenEnabled={fullscreenEnabled}
      pipEnabled={pipEnabled}
    />
  );
}

StaticVideoPlayer.defaultProps = {
  autoPlay: undefined,
  loop: undefined,
  fullscreenEnabled: undefined,
  pipEnabled: undefined,
};

export default StaticVideoPlayer;
