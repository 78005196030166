import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Content, ContentProvider, ContentType, RootState,
} from '../../../interfaces';
import ImgArenaGolf from './ImgArenaGolf';

function ImgArena() {
  const { eventInfo, content } = useSelector((state: RootState) => state.event);

  const isEventCentreAvailable = useMemo(() => content?.some(
    ({ type, provider }: Content) => provider === ContentProvider.IMG
    && type === ContentType.VISUALIZATION,
  ), [content]);

  const isGolfEvent = useMemo(
    () => eventInfo?.some(({ name }) => name.toLowerCase() === 'golf'),
    [eventInfo],
  );

  if (!isGolfEvent || !isEventCentreAvailable) {
    return null;
  }

  return (
    <section>
      {isGolfEvent && (
        <ImgArenaGolf />
      )}
    </section>
  );
}

export default ImgArena;
