import { combineReducers } from 'redux';
import auth from './auth';
import event from './event';
import application from './application';

export default combineReducers({
  auth,
  event,
  application,
});
