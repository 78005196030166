import { Content } from '../../interfaces';
import { EventActionTypes } from '../actionTypes';

export const updateEventId = (eventId: number) => ({
  type: EventActionTypes.UPDATE_EVENT_ID,
  payload: eventId,
});

export const unloadEvent = () => ({
  type: EventActionTypes.UNLOAD_EVENT,
});

export const updateEvent = (event: any) => ({
  type: EventActionTypes.UPDATE_EVENT,
  payload: event,
});

export const updateContent = (content: Content[]) => ({
  type: EventActionTypes.UPDATE_CONTENT,
  payload: content,
});
