import { useEffect, useState } from 'react';

const AUTO_HIDE_CONTROLS_TIME = 3000;

let timer: ReturnType<typeof setTimeout>;

const useVideoPlayerControlsVisibility = (isVisible = true) => {
  const [areControlsVisible, setAreControlsVisible] = useState<boolean>(isVisible);

  const cancelAutoHideControls = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  const autoHideControls = () => {
    cancelAutoHideControls();
    timer = setTimeout(() => {
      setAreControlsVisible(false);
    }, AUTO_HIDE_CONTROLS_TIME);
  };

  useEffect(() => () => cancelAutoHideControls(), []);

  return {
    areControlsVisible,
    setAreControlsVisible,
    autoHideControls,
    cancelAutoHideControls,
  };
};

export default useVideoPlayerControlsVisibility;
