import useConfigs from './useConfigs';

const useLocale = (): string => {
  const { language } = useConfigs(['language']);

  return language?.locales.find(
    (l) => l.language === language.default,
  )?.locale.replace('_', '-') || '';
};

export default useLocale;
