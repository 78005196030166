import { AuthActionTypes } from '../actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const setIsPortalLoggedIn = (loggedIn: boolean) => ({
  type: AuthActionTypes.SET_IS_PORTAL_LOGGED_IN,
  payload: loggedIn,
});

export const setSessionId = (sessionId: string) => ({
  type: AuthActionTypes.SET_SESSION_ID,
  payload: sessionId,
});
