import React from 'react';
import { useIntl } from 'react-intl';
import VideoPlayerControl, { VideoPlayerControlVariantEnum } from './VideoPlayerControl';
import { ReactComponent as IconMuted } from '../../../../assets/img/icon-muted.svg';
import { ReactComponent as IconUnmute } from '../../../../assets/img/icon-unmute.svg';

type VolumeVideoPlayerControlProps = {
  videoRef: HTMLVideoElement,
  isMuted: boolean,
  volume: number,
  onClick: React.MouseEventHandler,
}
function VolumeVideoPlayerControl({
  videoRef,
  isMuted,
  volume,
  onClick,
}: VolumeVideoPlayerControlProps) {
  const intl = useIntl();
  const volumeControlAriaLabel = intl.formatMessage({
    id: isMuted ? 'ACCESSIBILITY_VIDEO_PLAYER_UNMUTE'
      : 'ACCESSIBILITY_VIDEO_PLAYER_MUTE',
  });

  const toggleMute = () => {
    if (videoRef) {
      // eslint-disable-next-line no-param-reassign
      videoRef.muted = !videoRef.muted;
      if (videoRef.muted) {
        videoRef.setAttribute('muted', '');
      } else {
        videoRef.removeAttribute('muted');
      }
    }
  };

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    toggleMute();
    onClick(event);
  };

  return (
    <VideoPlayerControl
      variant={VideoPlayerControlVariantEnum.VOLUME}
      onClick={onClickHandler}
      aria-label={volumeControlAriaLabel}
    >
      {isMuted || volume === 0 ? (
        <IconMuted className="svg-inline--fa" />
      ) : (
        <IconUnmute className="svg-inline--fa" />
      )}
    </VideoPlayerControl>
  );
}

export default VolumeVideoPlayerControl;
