import { AnyAction } from 'redux';
import { EventState } from '../../interfaces';
import { EventActionTypes } from '../actionTypes';

const initialState: EventState = {
  id: undefined,
  name: undefined,
  state: undefined,
  start: undefined,
  eventInfo: undefined,
  content: undefined,
};

export default (state = initialState, { type, payload }: AnyAction = { type: null }) => {
  switch (type) {
    case EventActionTypes.UNLOAD_EVENT:
      return initialState;
    case EventActionTypes.UPDATE_EVENT_ID:
      return {
        ...state,
        id: payload,
      };
    case EventActionTypes.UPDATE_EVENT:
      return {
        ...state,
        name: payload.name,
        state: payload.state,
        start: payload.start,
        eventInfo: payload.eventInfo,
      };
    case EventActionTypes.UPDATE_CONTENT: {
      const firstKey = Object.keys(payload)[0];
      const content = payload[firstKey] || [];
      return {
        ...state,
        content,
      };
    }
    default:
      return state;
  }
};
