export type EventState = {
  id?: number;
  name?: string;
  state?: State;
  start?: string;
  eventInfo?: EventInfo[];
  content?: Content[];
}

export enum State {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}

export type EventInfo = {
  id: number,
  name: string,
}

export type Content = {
  type: ContentType,
  provider: ContentProvider
}

export enum ContentType {
  STREAM = 'STREAM',
  VISUALIZATION = 'VISUALIZATION',
  STATS = 'STATS'
}

export enum ContentProvider {
  IMG = 'IMG',
  SPORTRADAR = 'SPORTRADAR'
}
