export default {
  colors: {
    main: 'rgb(49, 96, 148)',
    white: '#fff',
    black: '#000',
    gold: '#fdb61b',
    blue: '#093a82',
    darkBlue: '#001b3a',
    lightBlue: '#eaeff5',
    lighterBlue: '#e8eff7',
    green: '#51b100',
    body: '#f1f2f4',
  },
};
