enum EventActionTypes {
  UNLOAD_EVENT = 'event/UNLOAD_EVENT',

  UPDATE_EVENT = 'event/UPDATE_EVENT',
  UPDATE_EVENT_ID = 'event/UPDATE_EVENT_ID',

  UPDATE_CONTENT = 'event/UPDATE_CONTENT',
}

export default EventActionTypes;
