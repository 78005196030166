import React from 'react';
import { useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import VideoPlayerControl, { VideoPlayerControlVariantEnum } from './VideoPlayerControl';

const { Icon } = UiComponentLibrary;

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderIcon = styled(Icon)`
  animation: ${spin} 1s linear infinite;
`;

function LoaderVideoPlayerControl() {
  const intl = useIntl();
  const loadingVideoAriaLabel = intl.formatMessage({
    id: 'ACCESSIBILITY_VIDEO_LOADING',
  });

  return (
    <VideoPlayerControl
      variant={VideoPlayerControlVariantEnum.LOADER}
      role="alert"
      aria-label={loadingVideoAriaLabel}
    >
      <LoaderIcon name="circle-notch" width={32} height={32} />
    </VideoPlayerControl>
  );
}

export default LoaderVideoPlayerControl;
