import { AnyAction } from 'redux';
import { ApplicationActionTypes } from '../actionTypes';

const initialState = {
  type: 'auto',
};

export default (state = initialState, { type, payload }: AnyAction = { type: null }) => {
  switch (type) {
    case ApplicationActionTypes.SET_TYPE:
      return {
        ...initialState,
        type: payload,
      };
    default:
      return state;
  }
};
