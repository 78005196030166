import { useEffect, useState } from 'react';
import { ConfigsState } from '../interfaces';
import useConfigs from './useConfigs';

const useTimeZone = (): string => {
  const [cageTime, setCageTime] = useState<ConfigsState['cageTime']>();

  const { api } = useConfigs(['api']);

  /**
   * Workaround to prevent fetching the cageTime before the api config.
   * As the cageTime configKey needs a custom url based on the api config,
   * we use the RSIConfigHandler customUrl param to fetch it.
   */
  useEffect(() => {
    let unsubscribe: () => void;

    if (api) {
      unsubscribe = RSIConfigHandler.subscribe(
        [{
          configKey: 'cageTime',
          customUrl: `${api?.apiUrl}service/account/time/${api?.cageCode}`,
        }],
        ({ data }) => {
          setCageTime(data);
        },
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [api]);

  return cageTime?.cageTimeZone || '';
};

export default useTimeZone;
