import React from 'react';
import { Provider } from 'react-redux';
import { InitArgs } from './interfaces';
import store from './store';
import App from './App';

interface ModuleProps extends InitArgs {
  initialArgs?: any;
}

function Module({ initialState, initialArgs }: ModuleProps) {
  return (
    <Provider store={store(initialState)}>
      <App initialArgs={initialArgs} />
    </Provider>
  );
}

Module.defaultProps = {
  initialArgs: undefined,
};

export default Module;
