import React, {
  useCallback, useEffect, useState,
} from 'react';
import axios from 'axios';
import frontRowSeat, { MessageTopics } from '@img-arena/front-row-seat';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { logger } from '../../../services';
import { RootState } from '../../../interfaces';
import useConfigs from '../../../hooks/useConfigs';

const IMG_ARENA_EXPAND_BUTTON_ID = 'img-arena-expand-button';

const ImgArenaUserId = {
  golf: 'G-D8DFJEEG12',
};

enum LanguageTag {
  ENG = 'en',
  SPA = 'es_419'
}

const EventCentreContainer = styled.div`
  height: 334px !important;
  margin-bottom: 12px !important;
`;

type EventCentreProps = {
  config: any;
  eventId: string;
  fightId?: string;
  sport: 'golf';
}

function EventCentre({
  config, eventId, fightId, sport,
}: EventCentreProps) {
  const { api: apiConfig, language: languageConfig } = useConfigs(['api', 'language']);
  const sessionId = useSelector((state: RootState) => state.auth.sessionId);
  const [eventCentreContainer, setEventCentreContainer] = useState<HTMLDivElement>();
  const [language, setLanguage] = useState<LanguageTag>();
  const [eventCentre, setEventCentre] = useState<ReturnType<typeof frontRowSeat.eventCentre>>();
  const defaultLanguage = languageConfig?.default;
  const kambiEventId = useSelector((state: RootState) => state.event.id);

  useEffect(() => {
    if (defaultLanguage === 'SPA') {
      setLanguage(LanguageTag.SPA);
    } else if (defaultLanguage === 'ENG') {
      setLanguage(LanguageTag.ENG);
    }
  }, [defaultLanguage]);

  const fetchVideoPlaybackToken = useCallback(async () => {
    if (!sessionId) {
      throw new Error('User not authenticated');
    }
    if (!apiConfig) {
      throw new Error('API configuration missing');
    }
    if (!kambiEventId) {
      throw new Error('Kambi eventId missing');
    }
    return axios.get(
      `${apiConfig?.apiUrl}service/sportsbook/events/content/stream/img/token`,
      {
        headers: {
          'X-Auth-Token': sessionId,
        },
        params: {
          eventId: kambiEventId,
        },
      },
    );
  }, [apiConfig, sessionId, kambiEventId]);

  const onVideoPlaybackAuthRequest = useCallback(async () => {
    try {
      const {
        data: { operatorId, token: auth, timeStamp: timestamp },
      } = await fetchVideoPlaybackToken();

      eventCentre?.emit(MessageTopics.VIDEO_PLAYBACK_AUTH_RESPONSE, {
        operatorId: `${operatorId}`,
        auth,
        timestamp,
      });
    } catch (error) {
      logger.error('Failed to init video playback', {
        ...(error instanceof Error) && { error: error.message || error.toString() },
      });
    }
  }, [eventCentre, fetchVideoPlaybackToken]);

  useEffect(() => {
    if (eventCentre) {
      eventCentre.on(MessageTopics.VIDEO_PLAYBACK_AUTH_REQUEST, onVideoPlaybackAuthRequest);
    }
    return () => {
      eventCentre?.removeCallback(MessageTopics.VIDEO_PLAYBACK_AUTH_REQUEST);
    };
  }, [eventCentre, onVideoPlaybackAuthRequest]);

  const createEventCentre = useCallback(() => {
    try {
      if (!config) {
        throw new Error('Missing Event Centre config');
      }
      if (!eventCentreContainer) {
        throw new Error('Missing Event Centre container node');
      }

      const userId = ImgArenaUserId[sport];

      const instance = frontRowSeat.eventCentre({
        operator: 'rushstreet',
        sport,
        targetModule: 'full',
        eventId: `${eventId}`,
        version: `${config.version}`,
        targetElementSelector: eventCentreContainer,
        language,
        ...config.env && { env: config.env },
        ...config.theme && { theme: config.theme },
        options: {
          videoPlaybackEnabled: !!(sessionId && config.videoPlaybackEnabled),
          ...config.units && { units: config.units },
          userId,
        },
        ...fightId && {
          initialContext: {
            view: 'Fight',
            fightId,
          },
        },
      });

      setEventCentre(instance);
    } catch (error) {
      logger.error('Failed to init event centre', {
        ...(error instanceof Error) && { error: error.message || error.toString() },
      });
    }
  }, [eventId, fightId, config, eventCentreContainer, language, sport, sessionId]);

  useEffect(() => {
    if (eventCentreContainer) {
      createEventCentre();
    }
    return () => {
      if (eventCentreContainer) {
        eventCentreContainer.childNodes.forEach((child) => {
          eventCentreContainer.removeChild(child);
        });
      }
    };
  }, [createEventCentre, eventCentreContainer]);

  if (!eventId || !config) {
    return null;
  }

  return (
    <EventCentreContainer
      ref={(node) => { if (node) { setEventCentreContainer(node); } }}
      role="region"
      aria-labelledby={IMG_ARENA_EXPAND_BUTTON_ID}
    />
  );
}

EventCentre.defaultProps = {
  fightId: undefined,
};

export default EventCentre;
