import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export enum VideoPlayerControlVariantEnum {
  VOLUME = 'volume',
  PLAY_PAUSE = 'play-pause',
  FULL_SCREEN = 'fullscreen',
  PIP = 'pip',
  LOADER = 'loader',
}

type VideoPlayerControlProps = {
  variant: VideoPlayerControlVariantEnum;
};

const controlButtonVariantPartialCss = ({
  variant,
}: VideoPlayerControlProps) => {
  const mapVariantToCss: Record<
    VideoPlayerControlVariantEnum,
    FlattenSimpleInterpolation
  > = {
    [VideoPlayerControlVariantEnum.VOLUME]: css`
      left: 0;
      top: 0;
    `,
    [VideoPlayerControlVariantEnum.PLAY_PAUSE]: css`
      right: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .svg-inline--fa {
        width: 30px;
        height: auto;

        @media only screen and (min-width: 768px) {
          width: 35px;
        }
      }
    `,
    [VideoPlayerControlVariantEnum.FULL_SCREEN]: css`
      bottom: 0;
      right: 0;
    `,
    [VideoPlayerControlVariantEnum.PIP]: css`
      top: 0;
      right: 0;
    `,
    [VideoPlayerControlVariantEnum.LOADER]: css`
      cursor: default;
      right: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `,
  };
  return mapVariantToCss[variant];
};

const VideoPlayerControl = styled.button<VideoPlayerControlProps>`
  position: absolute;
  background: transparent;
  border: 0;
  border-radius: 50%;
  padding: 5px;
  user-select: none;
  transition: opacity 0.3s ease;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
  z-index: 2;

  ${controlButtonVariantPartialCss}

  ${({ variant }) => variant !== VideoPlayerControlVariantEnum.PLAY_PAUSE && `
      .svg-inline--fa {
        width: 19px;
        height: auto;

        @media only screen and (min-width: 768px) {
          width: 23px;
        }
      }
    `}

  svg {
    color: white;
  }
`;

export default VideoPlayerControl;
