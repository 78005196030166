import { createGlobalStyle } from 'styled-components';

// stylelint-disable selector-max-id
const GlobalStyle = createGlobalStyle`
  .rsi-styles {
    svg {
      fill: #fff;
    }
  }
  
  iframe {
    border: 0;
    padding: 0;
  }
  
`;

export default GlobalStyle;
