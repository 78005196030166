import React, {
  useState,
  useEffect,
  MouseEvent,
} from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { logger } from '../../../services';
import FullscreenVideoPlayerControl from './controls/FullscreenVideoPlayerControl';
import LoaderVideoPlayerControl from './controls/LoaderVideoPlayerControl';
import PIPVideoPlayerControl from './controls/PIPVideoPlayerControl.tsx';
import PlayPauseVideoPlayerControl from './controls/PlayPauseVideoPlayerControl';
import VideoPlayerControlsWrapper from './controls/VideoPlayerControlsWrapper';
import VideoPlayerWrapper from './VideoPlayerWrapper';
import VolumeVideoPlayerControl from './controls/VolumeVideoPlayerControl';
import useVideoPlayerControlsVisibility from './useVideoPlayerControlsVisibility';

const VideoWrapper = styled.video`
  user-select: none;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  user-select: none;
  pointer-events: none;
`;

type VideoPlayerProps = {
  onLoaded?: (node: HTMLVideoElement) => void;
  onPlayingStatusChange?: (isPlaying: boolean) => void;
  onPlayPressed?: () => void;
  onPausePressed?: () => void;
  onPauseEventHandler?: () => void;
  children?: React.ReactNode;
  source?: string;
  autoPlay?: boolean;
  loop?: boolean;
  fullscreenEnabled?: boolean;
  pipEnabled?: boolean;
  pauseOnPipExit?: boolean;
};

function VideoPlayer({
  onLoaded,
  onPlayingStatusChange,
  onPlayPressed,
  onPausePressed,
  onPauseEventHandler,
  children,
  source,
  autoPlay = false,
  loop = false,
  fullscreenEnabled = false,
  pipEnabled = false,
  pauseOnPipExit = false,
}: VideoPlayerProps) {
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>();
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement>();
  const [hasStreamError, setHasStreamError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [isInPIPMode, setIsInPIPMode] = useState(false);
  const [isMuted, setIsMuted] = useState<boolean>(autoPlay);
  const [volume, setVolume] = useState<number>(1);
  const [streamStarted, setStreamStarted] = useState<boolean>(false);
  const [metadataLoaded, setMetadataLoaded] = useState<boolean>(false);
  const posterPlaceholder = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEiIGhlaWdodD0iMSIvPg==';

  useEffect(() => {
    if (videoRef && onLoaded) {
      onLoaded(videoRef);
    }
  }, [videoRef, onLoaded]);

  const {
    areControlsVisible,
    setAreControlsVisible,
    autoHideControls,
    cancelAutoHideControls,
  } = useVideoPlayerControlsVisibility(!autoPlay);

  useEffect(() => {
    if (videoRef) {
      videoRef.onplay = () => {
        if (RSIUtils.detector.isMobile) {
          setAreControlsVisible(false);
        }

        setIsPlaying(true);
      };

      videoRef.onpause = () => {
        setIsPlaying(false);
        onPauseEventHandler?.();
        cancelAutoHideControls();
        setAreControlsVisible(true);
      };

      videoRef.onerror = (error) => {
        logger.error('Error with stream', { ...(error instanceof Error && { error: error.message || error.toString() }) });
        setHasStreamError(true);
      };

      videoRef.onvolumechange = ({ target }) => {
        if (target) {
          setIsMuted((target as HTMLVideoElement).muted);
          setVolume((target as HTMLVideoElement).volume);
        }
      };

      videoRef.onloadeddata = () => setStreamStarted(true);
      videoRef.onloadedmetadata = () => setMetadataLoaded(true);
      videoRef.onwebkitfullscreenchange = () => setIsFullscreen(!!document.fullscreenElement);

      videoRef.onenterpictureinpicture = () => {
        setIsInPIPMode(true);
        const location = RSINavigationHandler.getCurrentLocation();

        videoRef.onleavepictureinpicture = (e: Event) => {
          setIsInPIPMode(false);
          setTimeout(() => {
            if (!videoRef.paused && !document.pictureInPictureElement) {
              /**
               * When clicked on PiP back (video keeps natively playing) -> should navigate
               * When clicked on PiP exit (video is natively paused) -> should not navigate
               */
              RSINavigationHandler.push(location);
              window.scrollTo(0, 0);
            }
            if (!e.target || pauseOnPipExit) {
              /**
               * When the target element has been destroyed - pause the background video.
               */
              videoRef.pause();
            }
          }, 0);
        };
      };
    }
  }, [
    videoRef, onPauseEventHandler, pauseOnPipExit, cancelAutoHideControls, setAreControlsVisible,
  ]);

  useEffect(() => {
    if (wrapperRef) {
      wrapperRef.onfullscreenchange = () => setIsFullscreen(!!document.fullscreenElement);
      wrapperRef.onwebkitfullscreenchange = () => setIsFullscreen(!!document.fullscreenElement);
    }
  }, [wrapperRef]);

  useEffect(() => {
    if (videoRef) {
      if (autoPlay) {
        /**
         * React does not set the muted attribute to video element
         * but both Android and iOS devices require
         * the muted attribute to autoplay videos
         *
         * https://github.com/facebook/react/issues/10389
         * https://developers.google.com/web/updates/2016/07/autoplay
         * https://developer.apple.com/documentation/webkit/delivering_video_content_for_safari
         */
        videoRef.setAttribute('muted', '');
        videoRef.muted = true;
      } else {
        videoRef.muted = false;
      }
    }
  }, [videoRef, autoPlay]);

  useEffect(() => {
    if (onPlayingStatusChange) {
      onPlayingStatusChange(isPlaying);
    }
  }, [isPlaying, onPlayingStatusChange]);

  const onControlClick = (
    event: MouseEvent<HTMLButtonElement>,
    isPlayPause: boolean = false,
  ) => {
    event.stopPropagation();

    if (isPlaying && !isPlayPause && RSIUtils.detector.isMobile) {
      autoHideControls();
    }
  };

  const togglePlay = async (event: MouseEvent<HTMLButtonElement>) => {
    onControlClick(event, true);
    cancelAutoHideControls();

    if (videoRef?.paused) {
      if (onPlayPressed) {
        onPlayPressed();
      }
      try {
        setHasStreamError(false);
        setIsLoading(true);
        await videoRef?.play();
      } catch (error) {
        logger.error('Error starting stream', { ...(error instanceof Error && { error: error.message || error.toString() }) });
      } finally {
        setIsLoading(false);
      }
    } else {
      onPausePressed?.();
      videoRef?.pause();
    }
  };

  const onVideoPlayerWrapperClick = () => {
    if (RSIUtils.detector.isMobile && isPlaying) {
      cancelAutoHideControls();

      if (!areControlsVisible) {
        autoHideControls();
      }

      setAreControlsVisible((value) => !value);
    }
  };

  const onVideoPlayerWrapperMouseEnter = () => {
    if (!RSIUtils.detector.isMobile) {
      setAreControlsVisible(true);
    }
  };
  const onVideoPlayerWrapperMouseLeave = () => {
    if (!RSIUtils.detector.isMobile && isPlaying) {
      setAreControlsVisible(false);
    }
  };

  /**
   * Android webview adds default poster if not specified.
   * Minimal 1x1 placeholder used instead
   */
  const videoPoster = RSIUtils.detector.isAndroid ? posterPlaceholder : undefined;

  return (
    <VideoPlayerWrapper
      ref={(node) => { if (node) { setWrapperRef(node); } }}
      hasError={hasStreamError}
      isFullscreen={isFullscreen}
      onClick={onVideoPlayerWrapperClick}
      onMouseEnter={onVideoPlayerWrapperMouseEnter}
      onMouseLeave={onVideoPlayerWrapperMouseLeave}
    >
      {hasStreamError && (
        <span role="alert">
          <FormattedMessage id="ISSUE_WITH_STREAM" />
        </span>
      )}

      {!hasStreamError && (
        <>
          {!streamStarted && children && (
            <ThumbnailWrapper>{children}</ThumbnailWrapper>
          )}
          <VideoWrapper
            ref={setVideoRef}
            playsInline
            webkit-playsInline
            disableRemotePlayback
            autoPlay={autoPlay}
            muted={autoPlay}
            loop={loop}
            poster={videoPoster}
          >
            {source && (
              <source src={source} onError={(error) => { logger.error('Error with stream', { error }); setHasStreamError(true); }} />
            )}
          </VideoWrapper>

          {isLoading && (
            <LoaderVideoPlayerControl />
          )}

          {videoRef && wrapperRef && (
            <VideoPlayerControlsWrapper
              isVisible={areControlsVisible}
              aria-hidden={!areControlsVisible}
            >
              <VolumeVideoPlayerControl
                videoRef={videoRef}
                isMuted={isMuted}
                volume={volume}
                onClick={onControlClick}
              />

              {!isLoading && (
                <PlayPauseVideoPlayerControl isPlaying={isPlaying} onClick={togglePlay} />
              )}

              {fullscreenEnabled && streamStarted && (
                <FullscreenVideoPlayerControl
                  videoRef={videoRef}
                  wrapper={wrapperRef}
                  onClick={onControlClick}
                  isFullscreen={isFullscreen}
                />
              )}

              {
                pipEnabled
                && document.pictureInPictureEnabled
                && metadataLoaded
                && streamStarted && (
                  <PIPVideoPlayerControl
                    videoRef={videoRef}
                    isInPIPMode={isInPIPMode}
                    onClick={onControlClick}
                  />
                )
              }
            </VideoPlayerControlsWrapper>
          )}
        </>
      )}
    </VideoPlayerWrapper>
  );
}

VideoPlayer.defaultProps = {
  onLoaded: undefined,
  onPlayingStatusChange: undefined,
  onPlayPressed: undefined,
  onPausePressed: undefined,
  onPauseEventHandler: undefined,
  children: undefined,
  source: undefined,
  autoPlay: false,
  loop: false,
  fullscreenEnabled: false,
  pipEnabled: false,
  pauseOnPipExit: false,
};

export default VideoPlayer;
