import React, { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import VideoPlayerControl, { VideoPlayerControlVariantEnum } from './VideoPlayerControl';
import { ReactComponent as IconPicInPic } from '../../../../assets/img/icon-pic-in-pic.svg';

type PIPVideoPlayerControlProps = {
  videoRef: HTMLVideoElement,
  isInPIPMode: boolean,
  onClick: React.MouseEventHandler
}
function PIPVideoPlayerControl({ videoRef, isInPIPMode, onClick }: PIPVideoPlayerControlProps) {
  const intl = useIntl();
  const pipControlAriaLabel = intl.formatMessage({
    id: isInPIPMode ? 'ACCESSIBILITY_VIDEO_PLAYER_EXIT_PIP'
      : 'ACCESSIBILITY_VIDEO_PLAYER_ENTER_PIP',
  });

  const togglePictureInPicture = () => {
    if (isInPIPMode) {
      document.exitPictureInPicture();
    } else if (videoRef?.requestPictureInPicture) {
      videoRef?.requestPictureInPicture();
    }
  };

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    onClick(event);
    togglePictureInPicture();
  };

  return (
    <VideoPlayerControl
      variant={VideoPlayerControlVariantEnum.PIP}
      onClick={onClickHandler}
      aria-label={pipControlAriaLabel}
    >
      <IconPicInPic className="svg-inline--fa" />
    </VideoPlayerControl>
  );
}

export default PIPVideoPlayerControl;
